<template>
    <div class="row g-0 app-auth-wrapper">
        <div class="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
            <div class="d-flex flex-column align-content-end" style="margin-top: 68px;">
                <div class="app-auth-body mx-auto">
                    <div class="app-auth-branding mb-4 app-logo">
                        <img class="logo-icon me-2" src="../assets/images/shared-circle-min.png" alt="logo">
                    </div>
                    <h2 class="auth-heading text-center mb-5">Log in to Shared-D</h2>
                    <div class="auth-form-container text-start">
                        <form class="auth-form login-form" id="myForm" onsubmit="event.preventDefault();">
                            <div class="email mb-3">
                                <label class="sr-only" for="name">Name</label>
                                <input id="name" name="name" class="form-control signin-email" placeholder="Name"
                                    required="required">
                            </div>
                            <div class="password mb-3">
                                <label class="sr-only" for="password">Password</label>
                                <input id="password" name="password" type="password" class="form-control signin-password"
                                    placeholder="Password" required="required">
                                <div class="extra mt-3 row justify-content-between">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="RememberPassword">
                                            <label class="form-check-label" for="RememberPassword">
                                                Remember me
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="forgot-password text-end">
                                            <a href="/">Forgot password?</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn app-btn-primary w-100 theme-btn mx-auto"
                                    @click="submitForm();">
                                    Log In
                                </button>
                            </div>
                        </form>

                        <div class="auth-option text-center pt-5">
                            Powered by<span style="font-weight: 900;"> SoleBizz </span>
                        </div>
                        <!-- <div class="auth-option text-center pt-5">No Account? Sign up <a class="text-link"
                                href="signup.html">here</a>.</div> -->
                    </div>

                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
            <div class="auth-background-holder">
            </div>
            <!-- <div class="auth-background-mask"></div> -->
            <!-- <div class="auth-background-overlay p-3 p-lg-5">
                <div class="d-flex flex-column align-content-end h-100">
                    <div class="h-100"></div>
                    <div class="overlay-content p-3 p-lg-4 rounded" style="text-align: center;">
                        <h5 class="mb-3 overlay-title">Explore The Power Of Sharing</h5>
                    </div>
                </div>
            </div>  -->
        </div>
    </div>
</template>

<style scoped></style>

<script>
export default {
    name: 'LogIn',
    data() {
        return {
            baseAPIURL: 'https://sharedd-api.solebizz.com'
        };
    },
    async mounted() {
        this.baseAPIURL = window.location.origin.includes('localhost') ? 'http://localhost:11110' : this.baseAPIURL;
    },
    methods: {
        async submitForm() {
            try {
                const form = document.forms["myForm"];

                if (!form.checkValidity()) return;

                const reqObject = {
                    name: document.forms["myForm"]["name"].value,
                    password: document.forms["myForm"]["password"].value
                };

                const response = (await
                    (await fetch(
                        this.baseAPIURL + '/auth/',
                        {
                            method: 'POST',
                            headers: {
                                Accept: 'application.json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(reqObject)
                        }
                    )).json()
                )['data'];

                window.$setCookie("user", document.forms["myForm"]["name"].value, 6);
                window.$setCookie("auth", response.auth, 6);
                this.$emit("loginSuccess");
            } catch (e) {
                console.log(e);
                alert("Invalid Credentials");
            }
        }
    }
}
</script>
