<template>
    <div id="app-sidepanel" class="app-sidepanel sidepanel-hidden">
        <div id="sidepanel-drop" class="sidepanel-drop"></div>
        <div class="sidepanel-inner d-flex flex-column">
            <a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none">&times;</a>
            <div class="app-branding">
                <a class="app-logo" href="/"><img class="logo-icon me-2" src="../assets/images/shared-circle.png"
                        alt="logo"><span class="logo-text">
                        <div>SHARED</div>
                        <div>DASHBOARD</div>
                    </span></a>

            </div>
            <!--//app-branding-->
            <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
                <ul class="app-menu list-unstyled accordion" id="menu-accordion">
                    <div v-for="(mOption, i) in mainOptions" :key="i">
                        <li class="nav-item">
                            <router-link class="nav-link" :class="{ 'active': $route.path == mOption.redirectTo }"
                                :to="mOption.redirectTo" replace>
                                <span class="nav-icon" :id="`icon-wrapper-${i}`">
                                    <i :class="mOption.icon" aria-hidden="true"></i>
                                </span>
                                <span class="nav-link-text">{{ mOption.title }}</span>
                            </router-link>

                        </li>
                    </div>
                    <!-- <li class="nav-item has-submenu">
                        <a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse"
                            data-bs-target="#submenu-1" aria-expanded="false" aria-controls="submenu-1">
                            <span class="nav-icon">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z" />
                                    <path
                                        d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
                                </svg>
                            </span>
                            <span class="nav-link-text">Pages</span>
                            <span class="submenu-arrow">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                        </a>
                        <div id="submenu-1" class="collapse submenu submenu-1" data-bs-parent="#menu-accordion">
                            <ul class="submenu-list list-unstyled">
                                <li class="submenu-item"><a class="submenu-link"
                                        href="notifications.html">Notifications</a></li>
                                <li class="submenu-item"><a class="submenu-link" href="account.html">Account</a></li>
                                <li class="submenu-item"><a class="submenu-link" href="settings.html">Settings</a></li>

                            </ul>
                        </div>
                    </li> -->
                </ul>
            </nav>
            <div class="app-sidepanel-footer">
                <nav class="app-nav app-nav-footer">
                    <ul class="app-menu footer-menu list-unstyled">
                        <li class="nav-item">
                            <a class="nav-link" href="/settings">
                                <span class="nav-icon">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear" fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
                                        <path fill-rule="evenodd"
                                            d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
                                    </svg>
                                </span>
                                <span class="nav-link-text">Settings</span>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="https://allshareone.com/products">
                                <span class="nav-icon">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-person"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M12 1H4a1 1 0 0 0-1 1v10.755S4 11 8 11s5 1.755 5 1.755V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
                                        <path fill-rule="evenodd" d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>
                                </span>
                                <span class="nav-link-text">Other Services</span>
                            </a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" href="https://allshareone.com">
                                <span class="nav-link-text">Powered by <b>SoleBizz</b></span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'sharedMenu',
    components: {},
    data() {
        return {
            mainOptions: [
                {
                    title: 'Leads',
                    icon: 'fa fa-user-plus',
                    redirectTo: '/table/leads',
                    defaultActive: true
                },
                {
                    title: 'Customers',
                    icon: 'fa fa-user-circle-o',
                    redirectTo: '/table/customers'
                },
                {
                    title: 'Artwork',
                    icon: 'fa fa-file-image-o',
                    redirectTo: '/table/artwork'
                },
                {
                    title: 'Labels',
                    icon: 'fa fa-tags',
                    redirectTo: '/table/labels'
                },
                {
                    title: 'Orders',
                    icon: 'fa fa-cubes',
                    redirectTo: '/table/orders'
                    // subMenu: [
                    //     {
                    //         title: 'New Orders',
                    //         redirectTo: '/orders/new'
                    //     },
                    //     {
                    //         title: 'Re-Orders',
                    //         redirectTo: '/orders/repeat'
                    //     }]
                },
                {
                    title: 'Payments',
                    icon: 'fa fa-money',
                    redirectTo: '/table/payments'
                    // subMenu: [
                    //     {
                    //         title: 'New Orders',
                    //         redirectTo: '/orders/new'
                    //     },
                    //     {
                    //         title: 'Re-Orders',
                    //         redirectTo: '/orders/repeat'
                    //     }]
                },
                {
                    title: 'Products',
                    icon: 'fa fa-th',
                    redirectTo: '/table/products'
                },
            ]
        };
    },
    mounted() {
    },
    methods: {
        handleRedirect(e, redirectTo) {
            e.preventDefault();
            e.stopPropagation();
            this.$router.replace({ path: redirectTo });
        }
    }
}
</script>