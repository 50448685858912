import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/table/:id',
    name: 'DashTable',
    component: () => import('../views/DashTable.vue')
  },
  {
    path: '/form/:id',
    name: 'sharedForm',
    component: () => import('../components/sharedForm.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
