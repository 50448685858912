<template>
  <section v-show="loading">
    <loader />
  </section>
  <section v-show="!loading">
    <section v-show="!loggedIn">
      <loginIn @loginSuccess="takeMeToDash()" />
    </section>
    <section v-show="loggedIn">
      <div class="app">

        <header class="app-header fixed-top">
          <sharedHeader />
          <sharedMenu />
        </header>
        <div class="app-wrapper">
          <router-view />

          <footer class="app-footer">
            <div class="container text-center py-3" style="display: none;">
              <small class="copyright">Designed with <span class="sr-only">love</span><i class="fas fa-heart"
                  style="color: #fb866a;"></i> by <a class="app-link" href="http://themes.3rdwavemedia.com"
                  target="_blank">Xiaoying Riley</a> for developers</small>

            </div>
          </footer>

        </div>
      </div>
    </section>
  </section>
</template>

<style></style>
<script>
import sharedHeader from '@/components/sharedHeader.vue'
import sharedMenu from '@/components/sharedMenu.vue'
import loginIn from '@/components/logIn.vue'
import loader from '@/components/loader.vue'

export default {
  name: 'App',
  components: {
    sharedHeader,
    sharedMenu,
    loginIn,
    loader
  },
  data() {
    return {
      loggedIn: false,
      loading: true
    }
  },
  watch: {
    $route(p, n) {
      if (p.fullPath != n.fullPath) {
        this.responsiveSidePanel();
      }
    }
  },
  mounted() {
    this.responsiveSidePanel();

    window.addEventListener('load', function () {
      const sidePanel = document.getElementById('app-sidepanel');
      let w = window.innerWidth;
      if (w >= 1200) {
        sidePanel.classList.remove('sidepanel-hidden');
        sidePanel.classList.add('sidepanel-visible');

      } else {
        sidePanel.classList.remove('sidepanel-visible');
        sidePanel.classList.add('sidepanel-hidden');
      }
    });

    window.addEventListener('resize', function () {
      const sidePanel = document.getElementById('app-sidepanel');
      let w = window.innerWidth;
      if (w >= 1200) {
        sidePanel.classList.remove('sidepanel-hidden');
        sidePanel.classList.add('sidepanel-visible');

      } else {
        sidePanel.classList.remove('sidepanel-visible');
        sidePanel.classList.add('sidepanel-hidden');
      }
    });

    this.takeMeToDash();
  },
  methods: {
    responsiveSidePanel() {
      const sidePanel = document.getElementById('app-sidepanel');
      let w = window.innerWidth;
      if (w >= 1200) {
        sidePanel.classList.remove('sidepanel-hidden');
        sidePanel.classList.add('sidepanel-visible');

      } else {
        sidePanel.classList.remove('sidepanel-visible');
        sidePanel.classList.add('sidepanel-hidden');
      }
    },
    takeMeToDash() {
      this.loading = true;
      setTimeout(() => {
        const auth = window.$getCookie("auth");
        if (auth) {
          this.loggedIn = true;
          this.$router.replace({ path: '/table/leads' });
        }
        this.loading = false;
      }, 3000);
    }
  }
}

</script>
