<template>
    <div class="main">
        <div>
            <img class="brandImage" src="../assets/images/shared-circle.png" />
        </div>
    </div>
    <div class="main">
        <div>
            <img src="../assets/images/spinner.svg" />
        </div>
    </div>
    <div class="main-foot">
        Powered by<span> SoleBizz </span>
    </div>
</template>

<style scoped>
.main {
    width: 300px;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.brandImage {
    height: 85px;
    width: 85px;
}

.main-foot {
    width: 300px;
    position: fixed;
    transform: translate(-50%, -50%);
    bottom: 20px;
    left: 50%;
    text-align: center;
}

.main-foot span {
    font-weight: 900;
}
</style>

<script>
export default {
    name: 'loaderScreen',
    props: {},
    data() {
        return {};
    },
    methods: {}
}
</script>
